import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const blogPosts = [
  { id: 1, image: './images/blog1.jpg', title: 'Blog Post 1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
  { id: 2, image: './images/blog2.jpg', title: 'Blog Post 2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
  { id: 3, image: './images/blog3.jpg', title: 'Blog Post 3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
  { id: 4, image: './images/blog4.jpg', title: 'Blog Post 4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
  { id: 5, image: './images/blog5.jpg', title: 'Blog Post 5', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
  { id: 6, image: './images/blog6.jpg', title: 'Blog Post 6', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
];

const Blog = () => {
  return (
    <BlogPageWrapper>
      <div className="blog-page">
        <h2>Our Blog</h2>
        <div className="blog-posts">
          {blogPosts.map((post, index) => (
            <motion.div
              key={post.id}
              className={`blog-post ${index % 2 === 0 ? 'reverse' : ''}`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div 
                className="blog-image-container"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <img src={post.image} alt={post.title} className="blog-image" />
              </motion.div>
              <div className="blog-description">
                <h3>{post.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: post.description }} />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </BlogPageWrapper>
  );
};
const BlogPageWrapper = styled.section`
  .blog-page {
    padding: 40px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
  }

  h2 {
    color: rgb(24, 24, 29);
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 40px;
  }

  .blog-posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .blog-post {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }

  .blog-image-container {
    flex: 1;
  }

  .blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-description {
    flex: 2;
    padding: 20px;
  }

  .blog-description h3 {
    margin: 0;
    font-size: 2rem;
    color: rgb(24, 24, 29);
  }

  .blog-description p {
   .blog-description p {
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.5;
    
    padding-left: 2rem;
}
    &:first-of-type {
      font-weight: 400;
      color: black; /* Primary color */
    }
  }

  .blog-post.reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    .blog-post {
      flex-direction: column;
    }

    .blog-description {
      padding: 20px;
    }
  }

  @media (max-width: 480px) {
    .blog-post {
      width: 100%;
    }
  }
`;
export default Blog;
