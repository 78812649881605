
import React from 'react';
import HeroSection from './Components/HeroSection';
import OurServices from './Services';
import Features from './Components/Features';
import Technologysolutions from './Components/Technologysolutions'


const Home = () => {
  const data = {
    name: "Allied Axis",
    image: "./images/hero4.png",
  };

  return (
    <>
      <HeroSection {...data} />
      <OurServices />
      <Features/>
      <Technologysolutions/>
    
    </>
  );
};

export default Home;

