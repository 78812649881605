import React from 'react';
import styled from 'styled-components';
import { FaStar, FaGift, FaGamepad, FaRocket, FaWhatsapp, FaPhone } from 'react-icons/fa';
import { motion } from 'framer-motion';

const servicesData = [
  {
    icon: <FaStar />,
    title: "Enhanced Loyalty Programs",
    description: "Transforming Existing Loyalty Programs to Next-Gen Customer Engagement Programs by introducing Plug and Play Solutions.",
  },
  {
    icon: <FaGift />,
    title: "Interactive Digital Channel Schemes",
    description: "Bring innovation in Meaningful utilization of incentives & Schemes to drive better business.",
  },
  {
    icon: <FaGamepad />,
    title: "Gamified Customer Engagement",
    description: "Interactive Games ready to integrate with any Digital platforms to enhance Customer Engagement.",
  },
  {
    icon: <FaRocket />,
    title: "Brand Activation Solutions",
    description: "Custom built Brand Activation solutions which utilize the power of AI, Gamification, Chatbot.",
  },
  {
    icon: <FaWhatsapp />,
    title: "Customized WhatsApp Chatbots",
    description: "Drive engagement through simplest and most used tool ‘WhatsApp’ via Chatbots to connect with your customers effectively.",
  },
  {
    icon: <FaPhone />,
    title: "360 Deg Communication",
    description: "Cost-Effective Customer Contact Solutions for Outbound, Inbound, Online Support Services using Advance Technologies for On-demand scalability.",
  },
];

const OurServices = () => {
  return (
    <ServicesWrapper>
      <h2 className="services-heading">Our Services</h2>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <motion.div
            key={index}
            className="service-card"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </motion.div>
        ))}
      </div>
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.section`
  padding: 5rem 2rem;
  background-color: #f9f9f9;
  text-align: center;

  .services-heading {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-weight:600;
    color: #333;
    &:hover {
      color: #00008B;
    }
  }

  .services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: center;
  }

  .service-card {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .service-icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 1rem;
  }

  .service-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .service-description {
    font-size: 1.5rem;
    color: #666;
  }

  @media(max-width: 700px) {
    .services-container {
      grid-template-columns: 1fr;
    }
      
  }
`;

export default OurServices;
