import React from "react";
import styled from "styled-components";
import { Button } from "../styles/Button";
import { NavLink } from "react-router-dom";
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <Wrapper>
      <section className="contact-short">
        <div className="grid grid-two-column">
          <div>
            <h3>Ready to get started?</h3>
            <h3>Start your journey with us!</h3>
          </div>

          <div className="contact-short-btn">
            <NavLink to="/contact">
              <Button className="button">Get Started</Button>
            </NavLink>
          </div>
        </div>
      </section>

      <footer>
        <div className="container grid grid-four-column">
          <div className="footer-about">
            <h3>Allied Axis Business Solutions</h3>
            <p className="para">We are committed to transforming loyalty programs into next-gen interactive customer experience programs.</p>
          </div>

          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="footer-social--icons">
              <div>
                <FaTwitter className="icons" />
              </div>
              <div>
                <FaLinkedin className="icons" />
              </div>
              <div>
                <FaEnvelope className="icons" />
              </div>
            </div>
          </div>

          <div className="footer-contact">
            <h3>Call Us</h3>
            <h3>+91 9899045547</h3>
          </div>
        </div>

        <div className="footer-bottom--section">
          <hr />
          <div className="container grid grid-two-column">
            <p>@{new Date().getFullYear()} Allied Axis. All Rights Reserved</p>

            <div>
             
            </div>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);
    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }

    h3 {
      font-size: 1.8rem;
    }
  }

  .contact-short-btn {
    justify-self: end;
    align-self: center;

    .button {
      transition: background-color 0.3s ease;
      background-color: rgb(17 112 191);
      &:hover {
        background-color: #ADD8E6;
      }
    }
  }

  footer {
    padding: 10rem 0 3rem 0;
    background-color: #1170bf;
    h3 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
      font-size: 1.6rem;
    }

    p {
      color: ${({ theme }) => theme.colors.white};
    }

    .footer-about {
      p {
        display: block; /* Ensure it's shown on larger screens */
      }
    }

    .footer-social--icons {
      display: flex;
      gap: 2rem;

      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};

        .icons {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
      }
    }

    .footer-bottom--section {
      padding-top: 9rem;

      hr {
        margin-bottom: 2rem;
        color: ${({ theme }) => theme.colors.hr};
        height: 0.1px;
      }

      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
        }

        div {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .contact-short {
      max-width: 80vw;
      padding: 3rem 5rem;
      transform: translateY(30%);
      h3 {
        font-size: 1.5rem;
      }
      .button {
        padding: 0.7rem 1rem;
        font-size: 1rem;
        width: 100px;
      }
    }

    footer {
      padding: 7rem 1rem;
      .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        .footer-about {
          text-align: center;
        }

        .footer-contact,
        .footer-social {
          text-align: center;
        }

        .footer-social--icons {
          justify-content: center;
          gap: 1rem;
        }
      }

      .footer-bottom--section {
        padding-top: 2rem;

        hr {
          margin-bottom: 1rem;
          color: ${({ theme }) => theme.colors.hr};
          height: 0.1px;
        }

        .container {
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 1rem;

          p {
            margin: 0;
          }

          div {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .contact-short {
      max-width: 80vw;
      padding: 2rem 1rem;
      transform: translateY(20%);
      h3 {
        font-size: 1rem;
      }
      .button {
        padding: 0.5rem 0.7rem;
        font-size: 0.9rem;
        width: 80px;
      }
    }

    footer {
      padding: 5rem 1rem;
      .container {
        grid-template-rows: 1fr;
        gap: 1.5rem;
        text-align: center;
         h3 {
         font-size: 1.4rem;
         }
        .footer-social--icons {
          justify-content: center;
          gap: 0.5rem;
        }
      }

      .footer-bottom--section {
        padding-top: 0rem;
        padding-bottom: 2rem;
        margin-bottom: 7rem;

        hr {
          margin-bottom: 1rem;
          color: ${({ theme }) => theme.colors.hr};
          height: 0.1px;
          font-size: 1rem;
        }

        .container {
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 0.5rem;

          p {
            margin: 0;
            font-size: 1.4rem;
          }

          div {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }

      .footer-about .para {
        display: none;
      }
    }
  }
`;

export default Footer;
