import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
const blogPosts = [
  { id: 1, image: './images/blog1.jpg', title: 'Blog Post 1',
     description: 'This is the first blog', link: '/blog/1' },
  { id: 2, image: './images/blog2.jpg', title: 'Blog Post 2', 
    description: 'This is the second blog', link: '/blog/2' },
  { id: 3, image: './images/blog3.jpg', title: 'Blog Post 3', description: 'This is the third blog', link: '/blog/3' },
  { id: 4, image: './images/blog4.jpg', 
    title: 'Blog Post 4', description: 'This is the fourth blog', link: '/blog/4' },
  { id: 5, image: './images/blog5.jpg', title: 'Blog Post 5', description: 'This is the fifth blog', link: '/blog/5' },
  { id: 6, image: './images/blog6.jpg', title: 'Blog Post 6', description: 'This is the sixth blog', link: '/blog/6' },
];

const Blog = () => {
  return (
    <BlogPageWrapper>
      <div className="blog-page">
        <h2 className='heading'>Our Blog</h2>
        <div className="blog-posts"
         
        >
          {blogPosts.map((post, index) => (
            <div key={post.id} className=
            {`blog-post ${index % 2 === 0 ? 'reverse' : ''}`}>
              <motion.div className="blog-image-container"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }} 
              >
                <img src={post.image} alt={post.title}
                 className="blog-image" />
              </motion.div>
              <div className="blog-description">
                <h3>{post.title}</h3>
                <p dangerouslySetInnerHTML=
                {{ __html: post.description }} />
                <Link to={'./blog'} className="read-more">Read More</Link>
              </div>
            </div>
          ))}
        
      </div>
      </div>
    </BlogPageWrapper>
  );
};

const BlogPageWrapper = styled.section`
  .blog-page {
    padding: 40px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
  }

  h2 {
    font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
  }



  .blog-posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
   
    max-height: calc(2 * 350px); 
  }




  .blog-post {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;

    flex-direction: column;
  }

  .blog-image-container {
    flex: 1;
  }

  .blog-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .blog-description {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .blog-description h3 {
    margin: 0;
    font-size: 1.25rem;
    color: rgb(24, 24, 29);
  }

  .blog-description p {
    margin: 0;
    font-size: 0.875rem;

    color: rgb(85, 85, 85);


  }

  .read-more {
    display: inline-block;
    margin-top: auto;
    font-size: 0.875rem;
    color: #007bff;
    text-decoration: none;
  }

  .read-more:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .blog-posts {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 480px) {
    .blog-posts {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export default Blog;
