import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import '@fontsource/poppins'; // Add Poppins font
import { FaBullhorn, FaBoxOpen, FaUsers, FaLightbulb } from 'react-icons/fa'; // Importing icons from react-icons

const Technologysolutions = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <Wrapper>
      <div className="container">
        <section className="solutions-header">
          <h1 className="main-title">Our Technology Solutions</h1>
          <p className="intro-text">
            Explore our wide range of tech solutions to enhance your brand activation, boost customer engagement, and drive business growth.
          </p>
        </section>

        {/* Interactive Games Section */}
        <section className="interactive-games">
          <h2 className="section-title">Interactive Games</h2>
          <p className="section-description">
            Engage your audience with our innovative and fun game categories that can boost brand engagement and loyalty.
          </p>

          <div className="game-categories">
            <div className="game-category">
              <h3 className="category-title">Luck Based Games</h3>
              <Slider {...settings}>
                <GameCard title="Spin the Wheel" image="/images/spinfinal.jpg" />
                <GameCard title="Rolling Roulette" image="/images/rollingfinal.jpg"/>
                <GameCard title="Scratch Card" image="/images/scratchfinal.jpg" />
                <GameCard title="Roll a Dice" image="/images/dicefinal.jpg" />
              </Slider>
            </div>

            <div className="game-category">
              <h3 className="category-title">Skill Based Games</h3>
              <Slider {...settings}>
                <GameCard title="Slice It" image="/images/slice.jpg" />
                <GameCard title="Puzzle" image="/images/puzzle.jpg" />
                <GameCard title="Hit the Target" image="/images/hit.jpg" />
                <GameCard title="Shoot it" image="/images/shoot.jpg" />
              </Slider>
            </div>

            <div className="game-category">
              <h3 className="category-title">Knowledge Based Games</h3>
              <Slider {...settings}>
                <GameCard title="Guess the Image" image="/images/guesstheimage.jpg" />
                <GameCard title="Quiz" image="/images/quizfinal.jpg" />
                <GameCard title="Survey" image="/images/survey.jpg" />
                <GameCard title="Guess the Word" image="/images/guessword.jpg" />
              </Slider>
            </div>
          </div>
        </section>

        {/* Engaging Campaigns Section */}
        <section className="engaging-campaigns">
          <h2 className="section-title">Engaging Campaigns</h2>
          <p className="section-description">
            We have custom solutions for driving campaigns and schemes that fuel business growth.
          </p>

          <div className="campaign-categories">
            <div className="campaign-row">
              <CampaignCard
                title="Business Driving Campaigns"
                description="Drive your channel partners to engage more in business and deliver superior results."
                icon={<FaBullhorn />}
              />
              <CampaignCard
                title="New Product Introduction"
                description="Capture the attention of customers with your new products and get ahead in your sales plan."
                icon={<FaBoxOpen />}
              />
            </div>
            <div className="campaign-row">
              <CampaignCard
                title="Customer Acquisition Campaigns"
                description="Focus on revenue growth from increased purchase intent, basket size, and lower acquisition costs."
                icon={<FaUsers />}
              />
              <CampaignCard
                title="Awareness Campaigns"
                description="Stand out from your competitors with interactive campaigns and increase time spent with your brand."
                icon={<FaLightbulb />}
              />
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const GameCard = ({ title, image }) => {
  return (
    <CardWrapper>
      <img src={image} alt={title} />
      <p>{title}</p>
    </CardWrapper>
  );
};

const CampaignCard = ({ title, description, icon }) => {
  return (
    <CardWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </CardWrapper>
  );
};

const Wrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #f7f7f7;

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .main-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 600;
  }

  .intro-text {
    font-size: 1.5rem;
    text-align: center;
    color: #555;
    margin-bottom: 3rem;
    font-family: 'Poppins', sans-serif;
  }

  .section-title {
    font-size: 2.2rem;
    margin-bottom: 1rem;
    color: #222;
    text-align: center;
    margin-top: 4rem;
    font-weight: 500;
  }

  .section-description {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 2rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }

  .game-categories {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .game-category {
    width: 32%;
  }

  .campaign-categories {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .campaign-row {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .category-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;

    .game-category {
      width: 100%;
    }

    .campaign-row {
      flex-direction: column;
    }

    .main-title,
    .section-title {
      font-size: 2rem;
    }

    .intro-text,
    .section-description {
      font-size: 1.2rem;
    }
  }
`;

const CardWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .card-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 500;
  }

  .card-description {
    font-size: 1.5rem;
    color: #555;
    font-family: 'Poppins', sans-serif;
  }

  p {
    font-size: 1.5rem;
    color: #333;
    font-family: 'Poppins', sans-serif;
  }

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .card-title {
      font-size: 1.5rem;
    }

    .card-description,
    p {
      font-size: 1.2rem;
    }
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export default Technologysolutions;
