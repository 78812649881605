import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const HeroSection = () => {
  return (
    <Wrapper>
      <div className="container grid grid-two-column">
        <div className="section-hero-data">
          <motion.p
            className="hero-top-data"
          >
            {/* Add any top data here */}
          </motion.p>
          <motion.h1
            className="hero-heading"
          >
            ALLIED AXIS
          </motion.h1>
          <motion.h4
            className="h4-heading"
           
          >
            Business Solutions
          </motion.h4>
          <motion.p
            className="hero-para"
           
          >
            We develops software, provides tech solutions, manages systems, and supports businesses with technology and digital transformation services.
          </motion.p>
        </div>

        <div className="section-hero-image">
          <picture>
            <motion.img
              src="./images/hero2.jpg"
              alt="Hero"
             
             
            />
          </picture>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 5rem 2rem;
  background-image: url('/images/rotate.jpg'); /* Add your background image here */
  background-size: cover; /* Ensure the background covers the entire section */
  background-position: center; /* Center the background image */

  .container {
    position: relative;
    z-index: 2;
  }

  .section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
  }

  .btn {
    max-width: 16rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #add8e6;
    }
  }

  .hero-top-data {
    padding: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.helper};
    
  }

  .hero-heading {
    text-transform: uppercase;
    font-size: 6.4rem;
    padding-right: 2rem;
    margin: 0;
    padding: 1rem;
    
    
   
    transition: transform 0.2s ease-in-out; /* Adds a slight scale effect on hover */
  }

  .hero-heading:hover {
    transform: scale(1.05); /* Scales up the heading on hover */
  }

  .h4-heading {
    
    font-size: 2.5rem;
    color: #333;
    margin: -5px;
    padding: 0rem 18px;
    transition: transform 0.2s ease-in-out; /* Adds a slight scale effect on hover */
  }

  .h4-heading:hover {
    transform: scale(1.05); /* Scales up the heading on hover */
  }

  .hero-para {
    padding: 1.5rem;
    color: black;
    margin-top: 1.5rem;
    margin-bottom: 2.4rem;
    max-width: 60rem;
    font-size: 2rem;
    line-height: 1.6;
    padding-bottom: 5rem;
    font-family: 'Raleway', sans-serif; /* Apply the Raleway font */
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  picture {
    text-align: center;
  }

  img {
    max-width: 130%;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem 1rem;

    .container {
      grid-template-columns: 1fr;
    }

    .hero-heading {
      font-size: 3.2rem;
    }

    .hero-para {
      font-size: 1.4rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem; /* Reduce margin-bottom */
    }

    .section-hero-data {
      display: block;
    }

    .btn {
      max-width: 100%;
      padding: 1.5rem 1rem;
      margin: 2rem 0;
    }

    img {
      display: none;
    }
  }
`;

export default HeroSection;
