import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <Wrapper>
      <section id='about'>
        <div className="container grid grid-two-column">
          <div className="section-hero-data">
            <motion.h2 
              className="hero-heading"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              About <span>Us</span>
            </motion.h2>
            <motion.p 
              className="hero-para"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              We are committed to transforming loyalty programs into next-gen interactive customer experience programs.
            </motion.p>
            <motion.p 
              className="hero-para"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              By leveraging the power of gamification, we elevate your existing loyalty or customer engagement programs to new heights. Our innovative approach ensures that your customers remain engaged, motivated, and captivated through interactive experiences.
            </motion.p>
          </div>
          
          <div className="section-hero-image">
            <motion.picture
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <motion.img src="./images/About.png" alt="about" 
                initial={{ x : 100 , opacity : 0 }}
                whileInView={{ x : 0, opacity : 1}}
                transition = {{delay : 0.2 , y : {type : "spring" , stiffness : 60 }, opacity:{duration:1}, ease:"easeIn",duration:1,}}
                whileHover={{ scale: 1.1}} 
              />
            </motion.picture>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 10rem 0.5rem;

  .container {
    max-width: 120rem;
    margin: 0 auto;
    display: grid;
    gap: 9rem;
  }

  .grid-two-column {
    grid-template-columns: repeat(2, 1fr);
  }

  .section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-heading {
    text-transform: uppercase;
    font-size: 4.4rem;
    color: ${({ theme }) => theme.colors.heading};
    font-weight: bold;
    letter-spacing: 0.1rem;
    display: inline-block;
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    transition: color 0.3s ease-in-out;

    span {
      color: ${({ theme }) => theme.colors.primary};
    }

    &:after {
      content: '';
      display: block;
      width: 50%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      transition: width 0.3s ease-in-out;
    }

    &:hover {
      color: #00008B;

      &:after {
        width: 100%;
      }
    }
  }

  .hero-para {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.text};
    padding-left: 2rem;
    &:first-of-type {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }
    
  @media (max-width: 600px) {
    .grid-two-column {
      grid-template-columns: 1fr;
    }
    .section-hero-data {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .section-hero-image {
      display: none;
    }
  }
`;

export default About;
