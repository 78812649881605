import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Features = () => {
  return (
    <Wrapper>
      <div className="container grid grid-two-column">
        {/* Image Section */}
        <div className="section-image">
          <motion.img
            src="./images/feature2.png"
            alt="Service"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: 'spring', stiffness: 60 },
              opacity: { duration: 1 },
              ease: 'easeIn',
              duration: 1,
            }}
          />
        </div>

        {/* Features Section */}
        <div className="section-data">
          <motion.h1
            className="heading"
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: 'spring', stiffness: 60 },
              opacity: { duration: 1 },
              ease: 'easeIn',
              duration: 1,
            }}
          >
            Key Features
          </motion.h1>

          <div className="feature-cards"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          >
            {featureData.map((feature, index) => (
              <motion.div
                key={index}
                className="feature-card"
                
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: index * 0.3,
                  duration: 0.8,
                  ease: 'easeInOut',
                }}
              >
                <div className="icon-container">
                  <img src={feature.icon} alt={feature.title} className="icon" />
                  <div className="feature-info">
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-text">{feature.text}</p>
                  </div>
                </div>
                <div className="arrow">➡</div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const featureData = [
  {
    icon: "./images/gamecms.jpg",
    title: "Game CMS",
    text: "Game flow, Landing & Operational page management, Dynamic content, Live response capturing, interactive pop-ups, and more."
  },
  {
    icon: "./images/easy.jpg",
    title: "Easy Embedding",
    text: "Embed with your Website, Native application, or social media."
  },
  {
    icon: "./images/branddesign.jpg",
    title: "Brand Design",
    text: "All campaigns, games, designs are in your brand's fonts and colors to ensure brand consistency."
  },
  {
    icon: "./images/flexiblefinal.jpg",
    title: "Absolutely flexible",
    text: "Responsive design for desktop, mobile, and tablets. Get seamless experience on any device."
  },
  {
    icon: "./images/dashboard.jpg",
    title: "Analytics & Dashboards",
    text: "Campaign analytics on customer dashboards running live to track performance and easy maneuvers."
  }
];

const Wrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #f4f4f4;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .section-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px; 
  }

  .section-data {
    flex: 1;
    padding: 2rem;
  }

  .heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .feature-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .feature-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3b96c3;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }

  .feature-card:hover {
    transform: scale(1.05);
  }

  .icon-container {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 60px;
    height: 60px;
    margin-right: 1rem;
  }

  .feature-info {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .feature-title {
    font-size: 1.8rem;
    color: #fff;
  }

  .feature-text {
    font-size: 1.4rem;
    color: #fff;
  }

  .arrow {
    font-size: 40px;
    color: white;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }

    .section-image {
      display: none; /* Hide image section on mobile and tablet */
    }

    .section-data {
      flex: 1;
      width: 100%;
    }

    .heading {
      font-size: 2.5rem;
    }

    .feature-card {
      flex-direction: column;
      align-items: flex-start;
    }

    .arrow {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .section-image {
      display: block; 
    }
  }
`;

export default Features;
